import React from 'react';
import styles from './styles.css.js';

interface AdditionalInfoProps {
  children: React.ReactNode;
}

export const AdditionalInfo = ({ children }: AdditionalInfoProps) => (
  <div className={styles['additional-info']}>{children}</div>
);
