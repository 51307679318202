import React from 'react';
import InternalColumnLayout, { COLUMN_TRIGGERS } from './internal';
import { getExternalProps } from '../internal/utils/external-props';
import { useContainerBreakpoints } from '../internal/hooks/container-queries';
import { applyDisplayName } from '../internal/utils/apply-display-name';
import useBaseComponent from '../internal/hooks/use-base-component';
import { ColumnLayoutProps } from './interfaces';

export { ColumnLayoutProps };

export default function ColumnLayout({ ...props }: ColumnLayoutProps) {
  const baseComponentProps = useBaseComponent('ColumnLayout');
  const [breakpoint, ref] = useContainerBreakpoints(COLUMN_TRIGGERS);
  const externalProps = getExternalProps(props);
  return <InternalColumnLayout {...externalProps} {...baseComponentProps} __breakpoint={breakpoint} ref={ref} />;
}

applyDisplayName(ColumnLayout, 'ColumnLayout');
