import clsx from 'clsx';
import React from 'react';
import styles from './styles.css.js';

export interface DarkHeaderProps {
  isRefresh: boolean;
  isMobile: boolean;
  toolsWidth: number;
  navigationWidth: number;
  children?: React.ReactNode;
  topOffset?: number;
  sticky?: boolean;
}

export function DarkHeader({
  isMobile,
  isRefresh,
  children,
  toolsWidth,
  navigationWidth,
  topOffset,
  sticky,
}: DarkHeaderProps) {
  const refreshStyles =
    isRefresh && !isMobile
      ? {
          paddingLeft: navigationWidth,
          marginLeft: -1 * navigationWidth,
          paddingRight: toolsWidth,
          marginRight: -1 * toolsWidth,
        }
      : {};
  return (
    <div
      className={clsx(
        styles['content-header'],
        sticky && styles['content-header-sticky'],
        isRefresh && 'awsui-context-content-header',
        isRefresh && styles['content-header-refresh']
      )}
      style={{ ...refreshStyles, top: topOffset }}
    >
      {children}
    </div>
  );
}
