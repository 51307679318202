import React from 'react';
import clsx from 'clsx';
import styles from './styles.css.js';
import { BaseComponentProps, getBaseProps } from '../../base-component';

export interface SelectableItemProps extends BaseComponentProps {
  children: React.ReactNode;
  ariaSelected?: boolean;
  selected?: boolean;
  highlighted?: boolean;
  disabled?: boolean;
  hasBackground?: boolean;
  isParent?: boolean;
  isChild?: boolean;
  isKeyboard?: boolean;
  virtualPosition?: number;
  padBottom?: boolean;
  isNextSelected?: boolean;
}

const SelectableItem = (
  {
    children,
    ariaSelected,
    selected,
    highlighted,
    disabled,
    hasBackground,
    isParent,
    isChild,
    isKeyboard,
    virtualPosition,
    padBottom,
    isNextSelected,
    ...restProps
  }: SelectableItemProps,
  ref: React.Ref<HTMLDivElement>
) => {
  const { className, ...rest } = getBaseProps(restProps);
  const classNames = clsx(className, styles['selectable-item'], {
    [styles.selected]: selected,
    [styles.highlighted]: highlighted,
    [styles['has-background']]: hasBackground,
    [styles.parent]: isParent,
    [styles.child]: isChild,
    [styles['is-keyboard']]: isKeyboard,
    [styles.disabled]: disabled,
    [styles.virtual]: virtualPosition !== undefined,
    [styles['pad-bottom']]: padBottom,
    [styles['next-item-selected']]: isNextSelected,
  });

  const style =
    virtualPosition !== undefined
      ? {
          transform: `translateY(${virtualPosition}px)`,
        }
      : undefined;

  const a11yProperties: Record<string, string | number | boolean | undefined> = {
    'aria-disabled': disabled,
  };

  if (disabled || isParent) {
    a11yProperties['aria-hidden'] = true;
  }

  if (ariaSelected) {
    a11yProperties['aria-selected'] = ariaSelected;
  }

  return (
    <li role="option" className={classNames} style={style} {...a11yProperties} {...rest}>
      {children}
      <div className={styles['measure-strut']} ref={ref} />
    </li>
  );
};

export default React.forwardRef(SelectableItem);
