import React from 'react';

interface TabTrapProps {
  focusNextCallback: FocusNextElement;
}

export interface FocusNextElement {
  (): void;
}

// This component handles focus-forwarding when navigating through the calendar grid.
// When the customer focuses that component the `next` callback function is called
// with forwards the focus.
const TabTrap = ({ focusNextCallback }: TabTrapProps) => {
  return <div tabIndex={0} onFocus={focusNextCallback} />;
};

export default TabTrap;
