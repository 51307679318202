import { useRef, useEffect, MutableRefObject } from 'react';
import { NonCancelableEventHandler, fireNonCancelableEvent } from '../events';
import FocusTracker from '../focus-tracker';

interface UseFocusTracker {
  (inputProps: {
    onBlur?: NonCancelableEventHandler<any>;
    onFocus?: NonCancelableEventHandler<any>;
    rootRef: MutableRefObject<HTMLElement | null>;
    viewportId?: string;
  }): void;
}

export const useFocusTracker: UseFocusTracker = ({ rootRef, onBlur, onFocus, viewportId }) => {
  const focusTracker = useRef<FocusTracker | null>(null);

  useEffect(() => {
    if (!rootRef.current) {
      return;
    }
    focusTracker.current = new FocusTracker(
      rootRef.current,
      {
        onFocusLeave: () => {
          fireNonCancelableEvent(onBlur);
        },
        onFocusEnter: () => {
          fireNonCancelableEvent(onFocus);
        },
      },
      viewportId
    );
    focusTracker.current.initialize();
    return () => {
      focusTracker.current?.destroy();
    };
  }, [rootRef, onBlur, onFocus, viewportId]);
};
