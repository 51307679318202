import React, { useEffect } from 'react';
import { useContainerQuery } from '../../internal/hooks/container-queries';

function getScrollableContainers(element: HTMLElement) {
  let node: HTMLElement | null = element;

  while ((node = node.parentElement) && node !== document.body) {
    if (['scroll', 'auto'].indexOf(getComputedStyle(node).overflow) > -1) {
      return node;
    }
  }
  return null;
}

export function useStickyPosition(topOffset = 0) {
  const [stickyHeight, stickyRef] = useContainerQuery(rect => rect.height);
  const [stickyWidth, placeholderRef] = useContainerQuery(rect => rect.width);
  const placeholder = <div ref={placeholderRef} />;

  useEffect(() => {
    if (!stickyRef.current || !placeholderRef.current) {
      return;
    }
    const stickyElement = stickyRef.current;
    const placeholder = placeholderRef.current;
    const scrollParent = getScrollableContainers(stickyElement);
    const handler = () => {
      const stickyBox = stickyElement.getBoundingClientRect();
      const containerBox = scrollParent ? scrollParent.getBoundingClientRect() : { top: 0 };
      if (stickyBox.top - containerBox.top <= topOffset) {
        stickyElement.style.position = 'fixed';
        stickyElement.style.width = `${stickyWidth}px`;
        stickyElement.style.top = `${topOffset}px`;
        placeholder.style.height = `${stickyHeight}px`;
      } else {
        stickyElement.style.position = '';
        stickyElement.style.width = ``;
        stickyElement.style.top = ``;
        placeholder.style.height = ``;
      }
    };
    const eventTarget = scrollParent || window;
    eventTarget.addEventListener('scroll', handler);
    handler();
    return () => eventTarget.removeEventListener('scroll', handler);
  }, [topOffset, stickyWidth, stickyHeight, stickyRef, placeholderRef]);

  return [stickyRef, placeholder] as const;
}
