import { OptionDefinition, OptionGroup } from './interfaces';

function defaultOptionDescription(option: OptionDefinition, parentGroup: OptionGroup | undefined) {
  return [parentGroup && parentGroup.label, option.value, option.label, option.description, option.labelTag]
    .concat(option.tags)
    .filter(el => !!el)
    .join(' ');
}

export default defaultOptionDescription;
