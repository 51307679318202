/* eslint-disable-next-line no-warning-comments */
// default delay for AWS-UI components defined by UX
export const DEBOUNCE_DEFAULT_DELAY = 200;

// instead of using this function directly, consider using useDebounceCallback hook
export default function debounce<CallbackType extends (...args: any[]) => void>(
  func: CallbackType,
  delay: number = DEBOUNCE_DEFAULT_DELAY
): CallbackType {
  let timeout: ReturnType<typeof setTimeout> | null;

  return function (...args: any[]) {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      timeout = null;
      func(...args);
    }, delay);
  } as CallbackType;
}
