import React from 'react';
import { applyDisplayName } from '../internal/utils/apply-display-name';
import InternalSpaceBetween from './internal';
import { SpaceBetweenProps } from './interfaces';
import useBaseComponent from '../internal/hooks/use-base-component';

export { SpaceBetweenProps };

export default function SpaceBetween({ direction = 'vertical', ...props }: SpaceBetweenProps) {
  const baseComponentProps = useBaseComponent('SpaceBetween');
  return <InternalSpaceBetween direction={direction} {...props} {...baseComponentProps} />;
}

applyDisplayName(SpaceBetween, 'SpaceBetween');
