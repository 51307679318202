import React, { useRef, useCallback } from 'react';
import { useResizeObserver } from './use-resize-observer';

export interface Rectangle {
  width: number;
  height: number;
}

/**
 * useContainerQuery ✨
 *
 * A performance-oriented hook that uses a ResizeObserver to measure the content box of the attached element
 * and re-renders when it's updated. To avoid firing off thousands of re-renders as the window is being resized
 * or elements are being moved, the hook takes a callback that receives the updated content box and "decides"
 * whether to re-render the component or not (by mapping it to a value that we call setState with).
 *
 * For example, you may choose to switch to a certain mode under a certain height:
 *     const [smallMode, ref] = useContainerQuery(rect => rect.height <= smallModeHeight, [smallModeHeight]);
 *
 * To actually re-render the whole container each resize frame, just provide the identity function:
 *     const [rect, ref] = useContainerQuery(rect => rect);
 *
 * If you're encoding complex information, remember to return prevState to prevent unnecessary re-renders:
 *     const [obj, ref] = useContainerQuery((rect, prevState) => shouldUpdate(rect) ? doStuff(rect) : prevState);
 */
export function useContainerQuery<S>(
  mapFn: (rect: Rectangle, prev: S | null) => S,
  deps: React.DependencyList = []
): [S | null, React.MutableRefObject<any>] {
  const elementRef = useRef<HTMLElement | null>(null);

  const refFn = useCallback(() => elementRef.current, [elementRef]);
  const memoizedMapFn = useCallback((entry, prevState) => {
    const contentBox = entry.contentBoxSize[0];
    return mapFn({ width: contentBox.inlineSize, height: contentBox.blockSize }, prevState);
    // mapFn has use-effect-like API with their own deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
  const state = useResizeObserver<S>(refFn, memoizedMapFn);

  return [state, elementRef];
}
