import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { getBaseProps } from '../internal/base-component';
import { warnOnce } from '../internal/logging';
import styles from './styles.css.js';
import icons from './icons';
import { InternalBaseComponentProps } from '../internal/hooks/use-base-component';
import { IconProps } from './interfaces';
import mergeRefs from 'react-merge-refs';
import { useVisualRefresh } from '../internal/hooks/use-visual-mode';

type InternalIconProps = IconProps &
  InternalBaseComponentProps & {
    badge?: boolean;
  };

function iconSizeMap(height: number) {
  if (height) {
    if (height >= 50) {
      return 'large';
    } else if (height >= 36) {
      return 'big';
    } else if (height >= 24) {
      return 'medium';
    } else if (height <= 16) {
      return 'small';
    } else {
      return 'normal';
    }
  }
}

const InternalIcon = ({
  name,
  size = 'normal',
  variant = 'normal',
  url,
  alt,
  svg,
  badge,
  __internalRootRef = null,
  ...props
}: InternalIconProps) => {
  const iconRef = useRef<HTMLElement>(null);
  // To ensure a re-render is triggered on visual mode changes
  useVisualRefresh(iconRef);
  const [parentHeight, setParentHeight] = useState(0);
  const contextualSize = size === 'inherit';
  const iconSize = contextualSize ? iconSizeMap(parentHeight) : size;
  const inlineStyles = { height: contextualSize ? `${parentHeight}px` : '' };
  const baseProps = getBaseProps(props);

  baseProps.className = clsx(
    baseProps.className,
    styles.icon,
    contextualSize && styles['icon-flex-height'],
    badge && styles.badge,
    !contextualSize && styles[`size-${iconSize}-mapped-height`],
    styles[`size-${iconSize}`],
    styles[`variant-${variant}`]
  );

  // Possible infinite loop is not a concern here because line
  // height should not change without an external state update.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!contextualSize || !iconRef.current) {
      return;
    }
    const { lineHeight } = getComputedStyle(iconRef.current);
    const newParentHeight = parseInt(lineHeight, 10);
    setParentHeight(newParentHeight);
  });

  if (svg) {
    if (url) {
      warnOnce(
        'Icon',
        'You have specified both `url` and `svg`. `svg` will take precedence and `url` will be ignored.'
      );
    }
    return (
      <span {...baseProps} ref={mergeRefs([iconRef, __internalRootRef])} aria-hidden="true" style={inlineStyles}>
        {svg}
      </span>
    );
  }

  if (url) {
    return (
      <span {...baseProps} ref={mergeRefs([iconRef, __internalRootRef])} style={inlineStyles}>
        <img src={url} alt={alt} />
      </span>
    );
  }

  const validIcon = name && Object.prototype.hasOwnProperty.call(icons, name);

  return (
    <span
      {...baseProps}
      // dangerouslySetInnerHTML is safe here, as we control the content coming from `icons`
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={validIcon ? { __html: icons[name] } : undefined}
      ref={mergeRefs([iconRef, __internalRootRef])}
      style={inlineStyles}
    />
  );
};

export { InternalIconProps };
export default InternalIcon;
