import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import mergeRefs from 'react-merge-refs';

import { InternalBaseComponentProps } from '../internal/hooks/use-base-component/index.js';
import { useFormFieldContext } from '../internal/context/form-field-context';
import { getBaseProps } from '../internal/base-component';
import { fireNonCancelableEvent } from '../internal/events';

import LiveRegion from '../internal/components/live-region';
import DropdownFooter from '../internal/components/dropdown-footer/index.js';
import { prepareOptions } from '../internal/components/option/utils/prepare-options';
import { OptionDefinition } from '../internal/components/option/interfaces';
import Dropdown from '../internal/components/dropdown';
import { useDropdownStatus } from '../internal/components/dropdown-status';

import { useSelect } from '../select/utils/use-select';
import { useNativeSearch } from '../select/utils/use-native-search';
import { useLoadItems } from '../select/utils/use-load-items';
import { useAnnouncement } from '../select/utils/use-announcement';
import { findOptionIndex } from '../select/utils/connect-options';
import PlainList, { SelectListProps } from '../select/parts/plain-list';
import VirtualList from '../select/parts/virtual-list';
import { checkOptionValueField } from '../select/utils/check-option-value-field.js';
import Filter from '../select/parts/filter';
import Trigger from '../select/parts/trigger';

import TokenGroup, { TokenGroupProps } from '../token-group/index.js';

import { MultiselectProps } from './interfaces';
import styles from './styles.css.js';

type InternalMultiselectProps = MultiselectProps & InternalBaseComponentProps;

const InternalMultiselect = React.forwardRef(
  (
    {
      options = [],
      filteringType = 'none',
      filteringPlaceholder,
      filteringAriaLabel,
      ariaRequired,
      placeholder,
      disabled,
      ariaLabel,
      statusType = 'finished',
      empty,
      loadingText,
      finishedText,
      errorText,
      recoveryText,
      noMatch,
      selectedAriaLabel,
      renderHighlightedAriaLive,
      selectedOptions = [],
      deselectAriaLabel,
      keepOpen = true,
      tokenLimit,
      i18nStrings,
      onBlur,
      onFocus,
      onLoadItems,
      onChange,
      virtualScroll,
      hideTokens = false,
      expandToViewport,
      __internalRootRef = null,
      ...restProps
    }: InternalMultiselectProps,
    externalRef: React.Ref<MultiselectProps.Ref>
  ) => {
    checkOptionValueField('Multiselect', 'options', options);

    const baseProps = getBaseProps(restProps);
    const formFieldContext = useFormFieldContext(restProps);

    const { handleLoadMore, handleRecoveryClick, fireLoadItems } = useLoadItems({
      onLoadItems,
      options,
      statusType,
    });

    const [filteringValue, setFilteringValue] = useState('');

    const updateSelectedOption = useCallback(
      (option: OptionDefinition) => {
        const newSelectedOptions = [...selectedOptions];
        const index = findOptionIndex(newSelectedOptions, option);
        if (index > -1) {
          newSelectedOptions.splice(index, 1);
        } else {
          newSelectedOptions.push(option);
        }
        fireNonCancelableEvent(onChange, {
          selectedOptions: newSelectedOptions,
        });
      },
      [onChange, selectedOptions]
    );

    const { filteredOptions, parentMap } = prepareOptions(options, filteringType, filteringValue);

    const rootRef = useRef<HTMLDivElement>(null);

    const scrollToIndex = useRef<SelectListProps.SelectListRef>(null);
    const {
      isOpen,
      highlightedOption,
      highlightedIndex,
      getTriggerProps,
      getFilterProps,
      getMenuProps,
      getOptionProps,
      isKeyboard,
      highlightOption,
      announceSelected,
    } = useSelect({
      selectedOptions,
      updateSelectedOption,
      options: filteredOptions,
      filteringType,
      onFocus,
      onBlur,
      externalRef,
      keepOpen,
      fireLoadItems,
      setFilteringValue,
    });

    const handleNativeSearch = useNativeSearch({
      isEnabled: filteringType === 'none' && isOpen,
      options: filteredOptions,
      highlightOption: highlightOption,
      highlightedOption: highlightedOption?.option,
      isKeyboard,
    });

    const isEmpty = !options || options.length === 0;
    const isNoMatch = filteredOptions && filteredOptions.length === 0;
    const dropdownStatus = useDropdownStatus({
      statusType,
      empty,
      loadingText,
      finishedText,
      errorText,
      recoveryText,
      isEmpty,
      isNoMatch,
      noMatch,
      onRecoveryClick: handleRecoveryClick,
    });

    const filter = (
      <Filter
        filteringType={filteringType}
        placeholder={filteringPlaceholder}
        ariaLabel={filteringAriaLabel}
        ariaRequired={ariaRequired}
        value={filteringValue}
        {...getFilterProps()}
      />
    );

    const trigger = (
      <Trigger
        placeholder={placeholder}
        disabled={disabled}
        ariaLabel={ariaLabel}
        triggerProps={getTriggerProps(disabled)}
        selectedOption={null}
        isOpen={isOpen}
        {...formFieldContext}
      />
    );

    const menuProps = {
      ...getMenuProps(),
      onLoadMore: handleLoadMore,
    };

    const announcement = useAnnouncement({
      announceSelected,
      highlightedOption,
      parentMap,
      selectedAriaLabel,
      renderHighlightedAriaLive,
    });

    const tokens: TokenGroupProps['items'] = selectedOptions.map(option => ({
      label: option.label,
      disabled: disabled || option.disabled,
      labelTag: option.labelTag,
      description: option.description,
      iconAlt: option.iconAlt,
      iconName: option.iconName,
      iconUrl: option.iconUrl,
      iconSvg: option.iconSvg,
      tags: option.tags,
      dismissLabel: deselectAriaLabel ? deselectAriaLabel(option) : undefined,
    }));

    useEffect(() => {
      scrollToIndex.current?.(highlightedIndex);
    }, [highlightedIndex]);

    const ListComponent = virtualScroll ? VirtualList : PlainList;

    const handleMouseDown = (event: React.MouseEvent) => {
      const target = event.target as HTMLElement;

      if (target !== document.activeElement) {
        // prevent currently focused element from losing it
        event.preventDefault();
      }
    };

    const showTokens = !hideTokens && tokens.length > 0;
    const handleTokenDismiss: TokenGroupProps['onDismiss'] = ({ detail }) => {
      const optionToDeselect = selectedOptions[detail.itemIndex];
      updateSelectedOption(optionToDeselect);
      const targetRef = getTriggerProps().ref;
      if (targetRef.current) {
        targetRef.current.focus();
      }
    };

    const tokenGroupI18nStrings: TokenGroupProps.I18nStrings = {
      limitShowFewer: i18nStrings?.tokenLimitShowFewer,
      limitShowMore: i18nStrings?.tokenLimitShowMore,
    };

    return (
      <div
        {...baseProps}
        ref={mergeRefs([rootRef, __internalRootRef])}
        className={clsx(styles.root, baseProps.className)}
        onKeyPress={handleNativeSearch}
      >
        <Dropdown
          open={isOpen}
          trigger={trigger}
          header={filter}
          onMouseDown={handleMouseDown}
          footer={dropdownStatus.isSticky ? <DropdownFooter content={dropdownStatus.content} /> : null}
          expandToViewport={expandToViewport}
        >
          <ListComponent
            listBottom={!dropdownStatus.isSticky ? <DropdownFooter content={dropdownStatus.content} /> : null}
            menuProps={menuProps}
            getOptionProps={getOptionProps}
            filteredOptions={filteredOptions}
            filteringValue={filteringValue}
            isKeyboard={isKeyboard.current}
            ref={scrollToIndex}
            hasDropdownStatus={dropdownStatus.content !== null}
            checkboxes={true}
          />
        </Dropdown>
        {showTokens && (
          <TokenGroup
            limit={tokenLimit}
            items={tokens}
            onDismiss={handleTokenDismiss}
            i18nStrings={tokenGroupI18nStrings}
          />
        )}
        <LiveRegion assertive={true}>{announcement}</LiveRegion>
      </div>
    );
  }
);

export default InternalMultiselect;
