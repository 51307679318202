import clsx from 'clsx';
import React from 'react';
import mergeRefs from 'react-merge-refs';
import { getBaseProps } from '../internal/base-component';
import styles from './styles.css.js';
import { Flash } from './flash';
import { useContainerBreakpoints } from '../internal/hooks/container-queries';
import { applyDisplayName } from '../internal/utils/apply-display-name';
import VisualContext from '../internal/components/visual-context';
import useBaseComponent from '../internal/hooks/use-base-component';
import { useVisualRefresh } from '../internal/hooks/use-visual-mode';
import { FlashbarProps } from './interfaces';

export { FlashbarProps };

export default function Flashbar({ items, ...restProps }: FlashbarProps) {
  const { __internalRootRef } = useBaseComponent('Flashbar');
  const [breakpoint, ref] = useContainerBreakpoints(['xs']);
  const isRefresh = useVisualRefresh(__internalRootRef);

  const baseProps = getBaseProps(restProps);
  return (
    <div
      {...baseProps}
      className={clsx(baseProps.className, styles.flashbar, styles[`breakpoint-${breakpoint}`])}
      ref={mergeRefs([ref, __internalRootRef])}
    >
      <VisualContext contextName="flashbar">
        {items &&
          items.map((item, index) => (
            <Flash key={index} className={isRefresh ? styles['flash-refresh'] : ''} {...item} />
          ))}
      </VisualContext>
    </div>
  );
}

applyDisplayName(Flashbar, 'Flashbar');
