import { useLayoutEffect, useCallback, RefObject } from 'react';
import { useResizeObserver } from '../internal/hooks/container-queries/use-resize-observer';
import stickyScrolling, { calculateScrollingOffset, scrollUpBy } from './sticky-scrolling';
import { useMobile } from '../internal/hooks/use-mobile';

function syncSizes(from: HTMLElement, to: HTMLElement) {
  const fromCells = Array.prototype.slice.apply(from.children);
  const toCells = Array.prototype.slice.apply(to.children);
  for (let i = 0; i < fromCells.length; i++) {
    let width = fromCells[i].style.width;
    // use auto if it is set by resizable columns or real size otherwise
    if (width !== 'auto') {
      width = `${fromCells[i].offsetWidth}px`;
    }
    toCells[i].style.width = width;
  }
}

export const useStickyHeader = (
  theadRef: RefObject<HTMLElement>,
  secondaryTheadRef: RefObject<HTMLElement>,
  secondaryTableRef: RefObject<HTMLElement>,
  tableWrapperRef: RefObject<HTMLElement>
) => {
  const isMobile = useMobile();
  const getPrimaryThead = useCallback(() => theadRef.current, [theadRef]);
  // Sync the sizes of the column header copies in the sticky header with the originals
  const syncColumnHeaderWidths = useCallback(() => {
    if (theadRef.current && secondaryTheadRef.current && secondaryTableRef.current && tableWrapperRef.current) {
      syncSizes(theadRef.current, secondaryTheadRef.current);
      secondaryTableRef.current.style.width = `${theadRef.current.offsetWidth}px`;
      tableWrapperRef.current.style.marginTop = `-${theadRef.current.offsetHeight}px`;
    }
  }, [theadRef, secondaryTheadRef, secondaryTableRef, tableWrapperRef]);
  useLayoutEffect(() => {
    syncColumnHeaderWidths();
    /* eslint-disable-next-line no-warning-comments */
    // Content is not going to be layed out until the next frame in angular,
    // so we need to sync the column headers again.
    setTimeout(() => syncColumnHeaderWidths(), 0);
    const secondaryTable = secondaryTableRef.current;
    const primaryTable = tableWrapperRef.current;
    return () => {
      if (secondaryTable) {
        secondaryTable.style.width = '';
      }
      if (primaryTable) {
        primaryTable.style.marginTop = '';
      }
    };
  });
  useResizeObserver(getPrimaryThead, syncColumnHeaderWidths);
  const scrollToTop = () => {
    if (!isMobile && theadRef.current && secondaryTheadRef.current && tableWrapperRef.current) {
      const scrollDist = calculateScrollingOffset(theadRef.current, secondaryTheadRef.current);
      if (scrollDist > 0) {
        scrollUpBy(scrollDist, tableWrapperRef.current);
      }
    }
  };
  const { scrollToItem } = stickyScrolling(tableWrapperRef, secondaryTheadRef);
  const scrollToRow = (itemNode: HTMLElement | null) => {
    if (!isMobile) {
      scrollToItem(itemNode);
    }
  };
  return { scrollToRow, scrollToTop };
};
