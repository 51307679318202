import clsx from 'clsx';
import React, { useImperativeHandle, useRef } from 'react';
import { TableForwardRefType, TableProps } from './interfaces';
import InternalContainer from '../container/internal';
import { getBaseProps } from '../internal/base-component';
import ToolsHeader from './tools-header';
import Thead, { TheadProps } from './thead';
import InternalStatusIndicator from '../status-indicator/internal';
import { useContainerQuery } from '../internal/hooks/container-queries';
import { supportsStickyPosition } from '../internal/utils/dom';
import SelectionControl from './selection-control';
import { checkSortingState, getColumnKey, getItemKey, toContainerVariant } from './utils';
import { useRowEvents } from './use-row-events';
import { focusMarkers, useFocusMove, useSelection } from './use-selection';
import { fireNonCancelableEvent } from '../internal/events';
import { isDevelopment } from '../internal/is-development';
import { checkColumnWidths, ColumnWidthsProvider, DEFAULT_WIDTH } from './use-column-widths';
import { useScrollSync } from '../internal/hooks/use-scroll-sync';
import { ResizeTracker } from './resizer';
import styles from './styles.css.js';
import { InternalBaseComponentProps } from '../internal/hooks/use-base-component';
import { useVisualRefresh } from '../internal/hooks/use-visual-mode';
import StickyHeader, { StickyHeaderRef } from './sticky-header';
import StickyScrollbar from './sticky-scrollbar';

export { TableProps };

type InternalTableProps<T> = TableProps<T> & InternalBaseComponentProps;

const InternalTable = React.forwardRef(
  <T,>(
    {
      header,
      footer,
      empty,
      filter,
      pagination,
      preferences,
      items = [],
      columnDefinitions,
      trackBy,
      loading,
      loadingText,
      selectionType,
      selectedItems = [],
      isItemDisabled,
      ariaLabels,
      onSelectionChange,
      onSortingChange,
      sortingColumn,
      sortingDescending,
      sortingDisabled,
      visibleColumns,
      stickyHeader,
      stickyHeaderVerticalOffset,
      onRowClick,
      onRowContextMenu,
      wrapLines,
      resizableColumns,
      onColumnWidthsChange,
      variant = 'container',
      __internalRootRef,
      ...rest
    }: InternalTableProps<T>,
    ref: React.Ref<TableProps.Ref>
  ) => {
    const baseProps = getBaseProps(rest);
    stickyHeader = stickyHeader && supportsStickyPosition();

    const [containerWidth, wrapperRef] = useContainerQuery<number>(({ width }) => width);
    const secondaryWrapperRef = React.useRef<HTMLDivElement>(null);
    const tableRef = useRef<HTMLTableElement>(null);
    const theadRef = useRef<HTMLTableRowElement>(null);
    const stickyHeaderRef = React.useRef<StickyHeaderRef>(null);
    const scrollbarRef = React.useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({ scrollToTop: stickyHeaderRef.current?.scrollToTop || (() => undefined) }));

    const handleScroll = useScrollSync([wrapperRef, scrollbarRef, secondaryWrapperRef], !supportsStickyPosition());

    const { moveFocusDown, moveFocusUp, moveFocus } = useFocusMove(selectionType, items.length);
    const { onRowClickHandler, onRowContextMenuHandler } = useRowEvents({ onRowClick, onRowContextMenu });
    const visibleColumnDefinitions = visibleColumns
      ? columnDefinitions.filter(column => column.id && visibleColumns.indexOf(column.id) !== -1)
      : columnDefinitions;
    const { isItemSelected, selectAllProps, getItemSelectionProps, updateShiftToggle } = useSelection({
      items,
      trackBy,
      selectedItems,
      selectionType,
      isItemDisabled,
      onSelectionChange,
      ariaLabels,
    });
    if (loading) {
      selectAllProps.disabled = true;
    }

    if (isDevelopment) {
      if (resizableColumns) {
        checkColumnWidths(columnDefinitions);
      }
      if (sortingColumn?.sortingComparator) {
        checkSortingState(columnDefinitions, sortingColumn.sortingComparator);
      }
    }

    const isRefresh = useVisualRefresh(tableRef);
    const computedVariant = isRefresh
      ? variant
      : ['embedded', 'full-page'].indexOf(variant) > -1
      ? 'container'
      : variant;
    const hasHeader = !!(header || filter || pagination || preferences);

    const theadProps: TheadProps = {
      containerWidth,
      selectionType,
      selectAllProps,
      columnDefinitions: visibleColumnDefinitions,
      variant: computedVariant,
      wrapLines,
      resizableColumns,
      sortingColumn,
      sortingDisabled,
      sortingDescending,
      onSortingChange,
      onFocusMove: moveFocus,
      onResizeFinish(newWidth) {
        const widthsDetail = columnDefinitions.map(
          (column, index) => newWidth[getColumnKey(column, index)] || (column.width as number) || DEFAULT_WIDTH
        );
        const widthsChanged = widthsDetail.some((width, index) => columnDefinitions[index].width !== width);
        if (widthsChanged) {
          fireNonCancelableEvent(onColumnWidthsChange, { widths: widthsDetail });
        }
      },
    };

    return (
      <ColumnWidthsProvider
        tableRef={tableRef}
        visibleColumnDefinitions={visibleColumnDefinitions}
        resizableColumns={resizableColumns}
        hasSelection={!!selectionType}
      >
        <InternalContainer
          {...baseProps}
          __internalRootRef={__internalRootRef}
          className={clsx(baseProps.className, styles.root)}
          header={
            <>
              {hasHeader && (
                <div className={clsx(styles['header-controls'], styles[`variant-${computedVariant}`])}>
                  <ToolsHeader header={header} filter={filter} pagination={pagination} preferences={preferences} />
                </div>
              )}
              {stickyHeader && (
                <StickyHeader
                  ref={stickyHeaderRef}
                  variant={computedVariant}
                  theadProps={theadProps}
                  wrapperRef={wrapperRef}
                  theadRef={theadRef}
                  secondaryWrapperRef={secondaryWrapperRef}
                  onScroll={handleScroll}
                />
              )}
            </>
          }
          disableHeaderPaddings={true}
          disableContentPaddings={true}
          variant={toContainerVariant(computedVariant)}
          __disableFooterPaddings={true}
          __disableFooterDivider={true}
          footer={
            footer && (
              <div className={clsx(styles['footer-wrapper'], styles[`variant-${computedVariant}`])}>
                <hr className={styles.divider} />
                <div className={styles.footer}>{footer}</div>
              </div>
            )
          }
          __stickyHeader={stickyHeader}
          __stickyOffset={stickyHeaderVerticalOffset}
          {...focusMarkers.root}
        >
          <div
            ref={wrapperRef}
            className={clsx(styles.wrapper, styles[`variant-${computedVariant}`], {
              [styles['has-footer']]: !!footer,
              [styles['has-header']]: hasHeader,
            })}
            onScroll={handleScroll}
          >
            <table
              ref={tableRef}
              className={clsx(styles.table, resizableColumns && styles['table-layout-fixed'])}
              // Browsers have weird mechanism to guess whether it's a data table or a layout table.
              // If we state explicitly, they get it always correctly even with low number of rows.
              role="table"
            >
              <Thead
                ref={theadRef}
                hidden={stickyHeader}
                onCellFocus={colIndex => stickyHeaderRef.current?.setFocusedColumn(colIndex)}
                onCellBlur={() => stickyHeaderRef.current?.setFocusedColumn(null)}
                {...theadProps}
              />
              <tbody>
                {loading || items.length === 0 ? (
                  <tr>
                    <td
                      colSpan={selectionType ? visibleColumnDefinitions.length + 1 : visibleColumnDefinitions.length}
                      className={styles['cell-merged']}
                    >
                      <div
                        className={styles['cell-merged-content']}
                        style={{
                          width:
                            (supportsStickyPosition() && containerWidth && Math.floor(containerWidth)) || undefined,
                        }}
                      >
                        {loading ? (
                          <InternalStatusIndicator type="loading" className={styles.loading}>
                            {loadingText}
                          </InternalStatusIndicator>
                        ) : (
                          <div className={styles.empty}>{empty}</div>
                        )}
                      </div>
                    </td>
                  </tr>
                ) : (
                  items.map((item, rowIndex) => {
                    const lastVisible = rowIndex === items.length - 1;
                    const isNextSelected = selectionType && !lastVisible && isItemSelected(items[rowIndex + 1]);
                    const isSelected = selectionType && isItemSelected(item);
                    return (
                      <tr
                        key={getItemKey(trackBy, item, rowIndex)}
                        className={clsx(styles.row, {
                          [styles['row-selected']]: isSelected,
                          [styles['next-row-selected']]: isNextSelected,
                        })}
                        onFocus={({ currentTarget }) => stickyHeaderRef.current?.scrollToRow(currentTarget)}
                        {...focusMarkers.item}
                        onClick={onRowClickHandler && onRowClickHandler.bind(null, rowIndex, item)}
                        onContextMenu={onRowContextMenuHandler && onRowContextMenuHandler.bind(null, rowIndex, item)}
                      >
                        {selectionType !== undefined && (
                          <SelectionControl
                            className={clsx(styles.cell, styles['body-cell'], styles['selection-control'])}
                            onFocusDown={moveFocusDown}
                            onFocusUp={moveFocusUp}
                            onShiftToggle={updateShiftToggle}
                            {...getItemSelectionProps(item)}
                          />
                        )}
                        {visibleColumnDefinitions.map((column, colIndex) => (
                          <td
                            key={getColumnKey(column, colIndex)}
                            style={
                              resizableColumns
                                ? {}
                                : {
                                    width: column.width,
                                    minWidth: column.minWidth,
                                    maxWidth: column.maxWidth,
                                  }
                            }
                            className={clsx(styles.cell, styles['body-cell'], wrapLines && styles['body-cell-wrap'])}
                          >
                            {column.cell(item)}
                          </td>
                        ))}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
            {resizableColumns && <ResizeTracker />}
          </div>
          <StickyScrollbar ref={scrollbarRef} wrapperRef={wrapperRef} tableRef={tableRef} onScroll={handleScroll} />
        </InternalContainer>
      </ColumnWidthsProvider>
    );
  }
) as TableForwardRefType;

export default InternalTable;
