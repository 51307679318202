import * as tokens from '../generated/styles/tokens';

export const categoryPalette = [
  tokens.colorChartsPaletteCategorical1,
  tokens.colorChartsPaletteCategorical2,
  tokens.colorChartsPaletteCategorical3,
  tokens.colorChartsPaletteCategorical4,
  tokens.colorChartsPaletteCategorical5,
  tokens.colorChartsPaletteCategorical6,
  tokens.colorChartsPaletteCategorical7,
  tokens.colorChartsPaletteCategorical8,
  tokens.colorChartsPaletteCategorical9,
  tokens.colorChartsPaletteCategorical10,
  tokens.colorChartsPaletteCategorical11,
  tokens.colorChartsPaletteCategorical12,
  tokens.colorChartsPaletteCategorical13,
  tokens.colorChartsPaletteCategorical14,
  tokens.colorChartsPaletteCategorical15,
  tokens.colorChartsPaletteCategorical16,
  tokens.colorChartsPaletteCategorical17,
  tokens.colorChartsPaletteCategorical18,
  tokens.colorChartsPaletteCategorical19,
  tokens.colorChartsPaletteCategorical20,
  tokens.colorChartsPaletteCategorical21,
  tokens.colorChartsPaletteCategorical22,
  tokens.colorChartsPaletteCategorical23,
  tokens.colorChartsPaletteCategorical24,
  tokens.colorChartsPaletteCategorical25,
  tokens.colorChartsPaletteCategorical26,
  tokens.colorChartsPaletteCategorical27,
  tokens.colorChartsPaletteCategorical28,
  tokens.colorChartsPaletteCategorical29,
  tokens.colorChartsPaletteCategorical30,
  tokens.colorChartsPaletteCategorical31,
  tokens.colorChartsPaletteCategorical32,
  tokens.colorChartsPaletteCategorical33,
  tokens.colorChartsPaletteCategorical34,
  tokens.colorChartsPaletteCategorical35,
  tokens.colorChartsPaletteCategorical36,
  tokens.colorChartsPaletteCategorical37,
  tokens.colorChartsPaletteCategorical38,
  tokens.colorChartsPaletteCategorical39,
  tokens.colorChartsPaletteCategorical40,
  tokens.colorChartsPaletteCategorical41,
  tokens.colorChartsPaletteCategorical42,
  tokens.colorChartsPaletteCategorical43,
  tokens.colorChartsPaletteCategorical44,
  tokens.colorChartsPaletteCategorical45,
  tokens.colorChartsPaletteCategorical46,
  tokens.colorChartsPaletteCategorical47,
  tokens.colorChartsPaletteCategorical48,
  tokens.colorChartsPaletteCategorical49,
  tokens.colorChartsPaletteCategorical50,
];
