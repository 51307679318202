/* eslint-disable no-warning-comments */
// When updating the list of key codes, don't forget
// to modify corresponding list in AWS-UI-TestUtilsCore
// to avoid failing unit tests
export enum KeyCode {
  pageUp = 33,
  pageDown = 34,
  end = 35,
  home = 36,
  backspace = 8,
  space = 32,
  down = 40,
  left = 37,
  right = 39,
  up = 38,
  escape = 27,
  enter = 13,
  tab = 9,
  shift = 16,
  control = 17,
  alt = 18,
  meta = 91,
}
