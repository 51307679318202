import React, { useContext } from 'react';
import clsx from 'clsx';
import { AppLayoutContext } from './context';
import styles from './styles.css.js';

/**
 * The CSS class 'awsui-context-content-header' needs to be added to the root element so
 * that the design tokens used are overridden with the appropriate values.
 */
export default function Header() {
  const { breadcrumbs, contentHeader, notifications } = useContext(AppLayoutContext);

  if (!contentHeader) {
    return null;
  }

  return (
    <header
      className={clsx(styles.content, 'awsui-context-content-header')}
      data-has-breadcrumbs={breadcrumbs ? true : false}
      data-has-notifications={notifications ? true : false}
    >
      {contentHeader}
    </header>
  );
}
