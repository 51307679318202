import React from 'react';
import InternalStatusIndicator, { StatusIndicatorProps } from './internal';
import { applyDisplayName } from '../internal/utils/apply-display-name';
import useBaseComponent from '../internal/hooks/use-base-component';

export { StatusIndicatorProps };

export default function StatusIndicator({ type = 'success', ...props }: StatusIndicatorProps) {
  const baseComponentProps = useBaseComponent('StatusIndicator');
  return <InternalStatusIndicator type={type} {...props} {...baseComponentProps} />;
}

applyDisplayName(StatusIndicator, 'StatusIndicator');
