import { ResizeObserver, ResizeObserverEntry } from '@juggle/resize-observer';
import { useState, useLayoutEffect, useEffect } from 'react';

export function useResizeObserver<S>(
  getElement: () => HTMLElement | null,
  mapFn: (rect: ResizeObserverEntry, prev: S | null) => S
) {
  const [state, setState] = useState<S | null>(null);

  useLayoutEffect(() => {
    const element = getElement();
    if (element) {
      setState(prevState => mapFn(new ResizeObserverEntry(element), prevState));
    }
    // This effect is only needed for the first render. Then `useEffect` below handles this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const element = getElement();
    if (element) {
      let connected = true;
      const observer = new ResizeObserver(entries => {
        // prevent from getting observe notifications on already unmounted component
        if (connected) {
          setState(prevState => mapFn(entries[0], prevState));
        }
      });
      observer.observe(element);
      return () => {
        connected = false;
        observer.disconnect();
      };
    }
  }, [getElement, mapFn]);

  return state;
}
