import clsx from 'clsx';
import React, { useRef } from 'react';
import { StickyHeaderContext, useStickyHeader } from './use-sticky-header';
import { getBaseProps } from '../internal/base-component';
import styles from './styles.css.js';
import { ContainerProps } from './interfaces';
import DarkRibbon from '../internal/components/dark-ribbon';
import { InternalBaseComponentProps } from '../internal/hooks/use-base-component';
import mergeRefs from 'react-merge-refs';
import { useVisualRefresh } from '../internal/hooks/use-visual-mode';

export interface InternalContainerProps extends Omit<ContainerProps, 'variant'>, InternalBaseComponentProps {
  __stickyHeader?: boolean;
  __stickyOffset?: number;
  __disableHeaderDivider?: boolean;
  __disableFooterDivider?: boolean;
  __disableFooterPaddings?: boolean;
  /**
   * Additional internal variant:
   * * `embedded` - Use this variant within a parent container (such as a modal,
   *                expandable section, container or split panel).
   * * `full-page` – Only for internal use in table, cards and other components
   */
  variant?: ContainerProps['variant'] | 'embedded' | 'full-page';
}

export default function InternalContainer({
  header,
  footer,
  children,
  variant = 'default',
  disableHeaderPaddings = false,
  disableContentPaddings = false,
  __stickyOffset,
  __stickyHeader = false,
  __internalRootRef = null,
  __disableHeaderDivider = false,
  __disableFooterDivider = false,
  __disableFooterPaddings = false,
  ...restProps
}: InternalContainerProps) {
  const baseProps = getBaseProps(restProps);
  const rootRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const isRefresh = useVisualRefresh(rootRef);
  const hasDynamicHeight = isRefresh && variant === 'full-page';
  const { isSticky, isStuck, stickyStyles } = useStickyHeader(rootRef, headerRef, __stickyHeader, __stickyOffset);

  return (
    <div
      {...baseProps}
      className={clsx(baseProps.className, styles.root, styles[`variant-${variant}`])}
      ref={mergeRefs([rootRef, __internalRootRef])}
    >
      {header && (
        <StickyHeaderContext.Provider value={{ isStuck }}>
          <div
            className={clsx(styles.header, {
              [styles['header-sticky-disabled']]: __stickyHeader && !isSticky,
              [styles['header-sticky-enabled']]: isSticky,
              [styles['header-dynamic-height']]: hasDynamicHeight,
              [styles['header-stuck']]: isStuck,
              [styles['with-paddings']]: !disableHeaderPaddings,
              [styles['with-divider']]: !__disableHeaderDivider,
            })}
            {...stickyStyles}
            ref={headerRef}
          >
            <DarkRibbon isRefresh={isRefresh} hasPlainStyling={variant !== 'full-page'}>
              {header}
            </DarkRibbon>
          </div>
        </StickyHeaderContext.Provider>
      )}
      <div
        className={clsx(styles.content, {
          [styles['with-paddings']]: !disableContentPaddings,
        })}
      >
        {children}
      </div>
      {footer && (
        <div
          className={clsx(styles.footer, {
            [styles['with-divider']]: !__disableFooterDivider,
            [styles['with-paddings']]: !__disableFooterPaddings,
          })}
        >
          {footer}
        </div>
      )}
    </div>
  );
}
