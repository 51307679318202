import React, { forwardRef } from 'react';
import { useStickyScrollbar } from './use-sticky-scrollbar';
import { useAppLayoutContext } from '../internal/context/app-layout-context';
import styles from './styles.css.js';
import mergeRefs from 'react-merge-refs';

interface StickyScrollbarProps {
  wrapperRef: React.RefObject<HTMLDivElement>;
  tableRef: React.RefObject<HTMLTableElement>;
  onScroll?: React.UIEventHandler<HTMLDivElement>;
}

export default forwardRef(StickyScrollbar);

function StickyScrollbar({ wrapperRef, tableRef, onScroll }: StickyScrollbarProps, ref: React.Ref<HTMLDivElement>) {
  const scrollbarRef = React.useRef<HTMLDivElement>(null);
  const scrollbarContentRef = React.useRef<HTMLDivElement>(null);
  const { stickyOffsetBottom } = useAppLayoutContext();
  useStickyScrollbar(scrollbarRef, scrollbarContentRef, tableRef, wrapperRef, stickyOffsetBottom);

  return (
    <div ref={mergeRefs([ref, scrollbarRef])} className={styles['sticky-scrollbar']} onScroll={onScroll}>
      <div ref={scrollbarContentRef} className={styles['sticky-scrollbar-content']} />
    </div>
  );
}
