import React from 'react';
import { TableForwardRefType, TableProps } from './interfaces';
import { applyDisplayName } from '../internal/utils/apply-display-name';
import InternalTable from './internal';
import useBaseComponent from '../internal/hooks/use-base-component';

export { TableProps };
const Table = React.forwardRef(
  <T,>({ items = [], selectedItems = [], ...props }: TableProps<T>, ref: React.Ref<TableProps.Ref>) => {
    const baseComponentProps = useBaseComponent('Table');
    return <InternalTable items={items} selectedItems={selectedItems} {...props} {...baseComponentProps} ref={ref} />;
  }
) as TableForwardRefType;

applyDisplayName(Table, 'Table');
export default Table;
